
import CommonUtils from '@/utils/common-utils';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { VForm } from 'vuetify/lib';
import ld from 'lodash';
import DateTimeUtils from '@/utils/date-time-utils';
import moment from 'moment';

const schoolprofile = namespace('schoolprofile');
const settings = namespace('settings');
const referencedata = namespace('referencedata');
const index = namespace('index');

@Component
export default class SchoolProfile extends Vue {
  localRefreshKey = CommonUtils.generateUUID();
  localSchoolProfile: any = '';
  tab = 0;
  allowTeacherStudents = true;
  billingSameAsSchoolInfo = true;
  countries: Array<any> = [];
  states: Array<any> = [];
  billingStates: Array<any> = [];

  useCurrentWeek = true;
  selectedDay: any;
  plansDueTime = '';
  weekDays = [
    { value: 8, prior: 1, priorDate: '1970-01-01', valueDate: '1970-01-08', text: this.$t('sundayLabel') },
    { value: 9, prior: 2, priorDate: '1970-01-02', valueDate: '1970-01-09', text: this.$t('mondayLabel') },
    { value: 10, prior: 3, priorDate: '1970-01-03', valueDate: '1970-01-10', text: this.$t('tuesdayLabel') },
    { value: 11, prior: 4, priorDate: '1970-01-04', valueDate: '1970-01-11', text: this.$t('wednesdayLabel') },
    { value: 12, prior: 5, priorDate: '1970-01-05', valueDate: '1970-01-12', text: this.$t('thursdayLabel') },
    { value: 13, prior: 6, priorDate: '1970-01-06', valueDate: '1970-01-13', text: this.$t('fridayLabel') },
    { value: 14, prior: 7, priorDate: '1970-01-07', valueDate: '1970-01-14', text: this.$t('saturdayLabel') }
  ]

  localRules = [
    (v: any) => !!v || this.$t('requiredLabel')
  ];

  $refs!: {
    schoolInformationEditorForm: InstanceType<typeof VForm>,
    billingInformationEditorForm: InstanceType<typeof VForm>,
  }

  @schoolprofile.Action
  updateSchoolProfile!: (params?: any) => Promise<any>;

  @schoolprofile.Action
  updateDistrictSettings!: (params: any) => Promise<any>;

  @settings.Getter('getUserInfo')
  userInfo!: any;

  @index.Getter
  hasBanner!: boolean;

  @referencedata.Action
  loadCountriesStates!: () => Promise<any>;

  @referencedata.Getter('getAllCountriesStates')
  countriesStates!: any;

  @Watch('tab')
  onTabChange() {
    this.localSchoolProfile = ld.cloneDeep(this.userInfo.schoolSettings.primarySchool);
    this.assignCountryStateToRespectiveObjects();
    this.billingInfoChanged(this.localSchoolProfile);
  }

  get scrollHeight() {
    if (this.$vuetify.breakpoint.mdAndUp) return this.hasBanner ? 'calc(100vh - 230px)' : 'calc(100vh - 150px)';
    return this.hasBanner ? 'calc(100vh - 400px)' : 'calc(100vh - 300px)';
  }

  private updateSchoolInformation(schoolProfile: any) {
    const form: any = this.$refs.schoolInformationEditorForm;
    if (form.validate()) {
      CommonUtils.showLoading();
      if (this.$currentUser.isDistrictAdmin && (this.allowTeacherStudents !== this.userInfo.schoolSettings.allowTeacherStudents)) {
        this.updateDistrictSettings({ allowTeacherStudents: this.allowTeacherStudents }).then(() => {
          this.userInfo.schoolSettings.allowTeacherStudents = this.allowTeacherStudents
        });
      }
      this.updateSchoolProfile(schoolProfile).then().finally(() => {
        CommonUtils.hideLoading();
        form.resetValidation();
        this.userInfo.schoolSettings.primarySchool = ld.cloneDeep(schoolProfile);
        return Promise.resolve(false);
      });
    }
  }

  private billingInfoChanged(schoolProfile: any) {
    if (schoolProfile.billingEmail === schoolProfile.contactEmail && schoolProfile.billingContact === schoolProfile.contactName && schoolProfile.billingAddress === schoolProfile.schoolAddress && schoolProfile.billingCity === schoolProfile.schoolCity && schoolProfile.billingCountry === schoolProfile.schoolCountry && schoolProfile.billingState === schoolProfile.schoolState && schoolProfile.billingZip === schoolProfile.schoolZip) {
      this.billingSameAsSchoolInfo = true;
    } else {
      this.billingSameAsSchoolInfo = false;
    }
  }

  updateBillingInformation(schoolProfile: any) {
    const form: any = this.$refs.billingInformationEditorForm;
    if (form.validate()) {
      CommonUtils.showLoading();
      this.updateSchoolProfile(schoolProfile).then().finally(() => {
        form.resetValidation();
        this.userInfo.schoolSettings.primarySchool = ld.cloneDeep(schoolProfile);
        this.localRefreshKey = CommonUtils.generateUUID();
        CommonUtils.hideLoading();
        return Promise.resolve(false);
      });
    }
  }

  private billingInfoSameAsSchoolInfo() {
    if (this.billingSameAsSchoolInfo === true) {
      this.localSchoolProfile.billingEmail = this.localSchoolProfile.contactEmail
      this.localSchoolProfile.billingContact = this.localSchoolProfile.contactName
      this.localSchoolProfile.billingAddress = this.localSchoolProfile.schoolAddress
      this.localSchoolProfile.billingCity = this.localSchoolProfile.schoolCity
      this.localSchoolProfile.billingCountryObj = this.localSchoolProfile.schoolCountryObj;
      this.loadStateOptions(this.localSchoolProfile.billingCountryObj, 'Billing');
      this.localSchoolProfile.billingStateObj = this.localSchoolProfile.schoolStateObj;
      this.localSchoolProfile.billingCountry = this.localSchoolProfile.billingCountryObj.code;
      this.localSchoolProfile.billingState = this.localSchoolProfile.billingStateObj.code;
      this.localSchoolProfile.billingZip = this.localSchoolProfile.schoolZip
      this.localSchoolProfile.billingFax = this.localSchoolProfile.contactNumber
    } else {
      this.localSchoolProfile.billingEmail = ''
      this.localSchoolProfile.billingContact = ''
      this.localSchoolProfile.billingAddress = ''
      this.localSchoolProfile.billingCity = ''
      this.localSchoolProfile.billingCountryObj = null;
      this.billingStates = [];
      this.localSchoolProfile.billingStateObj = null;
      this.localSchoolProfile.billingZip = ''
      this.localSchoolProfile.billingFax = ''
    }
  }

  private countrySelected(countryObject: any, purpose: string) {
    if (countryObject) {
      const country = countryObject.code;
      if (purpose === 'School') {
        this.localSchoolProfile.schoolCountry = country;
        this.localSchoolProfile.schoolState = '';
      } else if (purpose === 'Billing') {
        this.localSchoolProfile.billingCountry = country;
        this.localSchoolProfile.billingState = '';
      }
      this.loadStateOptions(countryObject, purpose);
      this.billingInfoChanged(this.localSchoolProfile);
    }
  }

  private loadStateOptions(countryObject: any, purpose: string) {
    if (countryObject) {
      const country = countryObject.code;
      const stateOptions = [];
      for (const state in this.countriesStates[country].states) {
        stateOptions.push({
          code: this.countriesStates[country].states[state].shortValue,
          stateName: this.countriesStates[country].states[state].value
        });
      }
      if (purpose === 'Billing') {
        this.billingStates = stateOptions
      } else {
        this.states = stateOptions;
      }
    }
  }

  private stateSelected(stateObject: any, forSchool: boolean) {
    if (forSchool) {
      this.localSchoolProfile.schoolState = stateObject.code;
    } else {
      this.localSchoolProfile.billingState = stateObject.code;
    }
    this.billingInfoChanged(this.localSchoolProfile);
  }

  private assignCountryStateToRespectiveObjects() {
    this.localSchoolProfile.billingCountryObj = this.countries.find((country: any) => {
      return country.code === this.localSchoolProfile.billingCountry;
    });
    this.loadStateOptions(this.localSchoolProfile.billingCountryObj, 'Billing');
    this.billingInfoChanged(this.localSchoolProfile);
    this.localSchoolProfile.billingStateObj = this.billingStates.find((state: any) => {
      return state.code === this.localSchoolProfile.billingState;
    });
    this.localSchoolProfile.schoolCountryObj = this.countries.find((country: any) => {
      return country.code === this.localSchoolProfile.schoolCountry;
    });
    this.countrySelected(this.localSchoolProfile.schoolCountryObj, 'None');
    this.localSchoolProfile.schoolStateObj = this.states.find((state: any) => {
      return state.code === this.localSchoolProfile.schoolState;
    });
  }

  timezoneDate(date:any) {
    if (!moment.utc(date).isValid()) date = '01/08/1970 11:59 PM';
    return moment.utc(date).utcOffset(moment.utc().local().utcOffset()).format('MM/DD/YYYY hh:mm A');
  }

  plansDueDateSaveFormat(date:any) {
    return moment(new Date(date)).add(-1 * moment.utc().local().utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm');
  }

  updateDueDate() {
    if (this.useCurrentWeek) {
      const constructedTime = this.selectedDay.valueDate + ' ' + this.plansDueTime;
      this.localSchoolProfile.settings.plansDueDate = this.plansDueDateSaveFormat(constructedTime);
    } else {
      const constructedTime = this.selectedDay.priorDate + ' ' + this.plansDueTime;
      this.localSchoolProfile.settings.plansDueDate = this.plansDueDateSaveFormat(constructedTime);
    }
  }

  private initialize() {
    CommonUtils.showLoading();
    this.localRefreshKey = CommonUtils.generateUUID();
    this.localSchoolProfile = ld.cloneDeep(this.userInfo.schoolSettings.primarySchool);
    if (this.$currentUser.isDistrictAdmin) {
      this.allowTeacherStudents = this.userInfo.schoolSettings.allowTeacherStudents;
    }
    const localPlansDueDate = (this.localSchoolProfile.settings.plansDueDate) ? this.timezoneDate(this.localSchoolProfile.settings.plansDueDate) : '01/08/1970 11:59 PM';
    const dateNum = DateTimeUtils.toDate(localPlansDueDate.substring(0, 10)).getDate();
    this.selectedDay = this.weekDays.find((d) => [d.value, d.prior].includes(dateNum));
    this.useCurrentWeek = dateNum >= 8;
    this.plansDueTime = localPlansDueDate.substring(11);

    this.loadCountriesStates().then(() => {
      for (const country in this.countriesStates) {
        this.countries.push({
          code: this.countriesStates[country].shortValue,
          countryName: this.countriesStates[country].value
        });
      }
      this.assignCountryStateToRespectiveObjects();
    })
    this.billingInfoChanged(this.localSchoolProfile);
    CommonUtils.hideLoading();
  }

  created() {
    this.initialize();
  }
}
